import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import { DateOptions } from "@utils/dateFormat";
import { CustomMarkdownComponents } from "@utils/markdownRendering";
import { AuthorFooterCard, AuthorHeaderCard } from "@ui/BlogAuthorCards";
import useOnScreen from "@utils/intersectionHook";
import useDebounce from "@utils/debounce";
import RecommendedArticles from "@components/RecommendedArticles";
import sluggify from "@utils/sluggify";
import Breadcrumb from "@components/Breadcrumb";
import { DeepNonNullable } from "ts-essentials";
import { SpecificPostDataQuery } from "../../../graphqlTypes";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody, mdxExcerpt } from "@utils/mdxUtils";
import { MDXProvider } from "@mdx-js/react";
import NewsletterBanner from "@ui/NewsletterBanner";
import { BlogPostJsonLd, GatsbySeo } from "gatsby-plugin-next-seo";
import { useLocation } from "@reach/router";

export const query = graphql`
  query SpecificPostData($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allStrapiBlogPosts(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          slug
          publish_at
          childStrapiBlogPostsContent {
            childMdx {
              body
              rawBody
              excerpt
            }
          }
          FeaturedImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 95
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
          blog_author {
            AuthorName
            AuthorJobTitle
            AuthorFacebook
            AuthorTwitter
            AuthorLinkedIn
            AuthorDescription
            AuthorImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95)
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BlogPost = ({
  data,
  location,
}: {
  data: DeepNonNullable<SpecificPostDataQuery>;
  location: Location;
}) => {
  const { node } = data.allStrapiBlogPosts.edges[0];

  const {
    childStrapiBlogPostsContent: Content,
    id: postID,
    FeaturedImage: {
      localFile: {
        childImageSharp: {
          gatsbyImageData: {
            images: {
              fallback: { src: featuredImageSource },
            },
          },
        },
      },
    },
    title,
    blog_author,
    publish_at,
  } = node;

  const {
    AuthorName,
    AuthorJobTitle,
    AuthorFacebook,
    AuthorTwitter,
    AuthorLinkedIn,
    AuthorDescription,
    AuthorImage: {
      localFile: {
        childImageSharp: { gatsbyImageData },
      },
    },
  } = blog_author;

  const { siteUrl } = data.site.siteMetadata;
  const { pathname } = useLocation();

  const [activeSection, setActiveSection] = useState<string>(null!);
  const [sections, setSection] = useState<string[]>([]);
  /** To Avoid glitching effect on frequent updates of activeSection */
  const debouncedSection = useDebounce<string>(activeSection, 300);

  /** Custom Rendering for specific Markdown Elememts */
  const Rendering = {
    ...CustomMarkdownComponents,
    h2: ({ ...props }) => {
      const { children } = props;
      const text = children[0]?.toString() as string;
      const ref = useRef<HTMLHeadingElement>(null);
      useOnScreen(ref, text, setActiveSection);
      return <h2 className="mb-9" id={sluggify(text)} ref={ref} {...props} />;
    },
  };

  /**
   * Get all H2 Paragraphs
   * Before rendering
   */
  useEffect(() => {
    const h2Regex = /^## (.*?)\n/gim;
    const h2Tags = Content.childMdx.rawBody.match(h2Regex);
    // Example result: "## Testing again the blog functionality\n"
    const cleanTags = h2Tags?.map(tag => tag.slice(3, -1));
    setSection(cleanTags?.reverse() as string[]);
  }, []);

  /**
   *
   * Custom Scrolling Effects to handle Author Side Card Stickyness
   *
   */
  const [topPosition, setTopPosition] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 200) {
      setTopPosition(true);
    } else {
      setTopPosition(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout>
      <Seo
        title={title}
        featuredImageURL={featuredImageSource}
        description={mdxExcerpt(Content)}
      />

      <GatsbySeo
        openGraph={{
          type: "article",
          article: {
            publishedTime: publish_at,
          },
          images: [
            {
              url: featuredImageSource,
            },
          ],
        }}
      />

      <BlogPostJsonLd
        title={title}
        description={mdxExcerpt(Content)}
        url={`${siteUrl}${pathname}`}
        authorName={blog_author.AuthorName}
        datePublished={publish_at}
      />

      <div className="div-block flex flex-col max-w-7xl mx-5 md:mx-auto px-4 xl:px-0 mt-4 mb-4">
        <Breadcrumb
          parents={[{ title: "Blog", url: "/blog/" }]}
          title={title}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <h1 className="text-4xl text-white font-semibold mt-16 mb-10">
              {title}
            </h1>
            <p className="text-sm text-gray-300 font-light">
              Last updated on{" "}
              {new Date(publish_at).toLocaleString("en-US", DateOptions)}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-accuRankerCream full-width-strip">
        <div className="max-w-fit bg-accuRankerCream w-full py-12 mx-5 md:m-auto px-4 xl:px-0">
          <div className="max-w-7xl relative mx-auto px-4 xl:px-0 mt-4 mb-4 grid grid-cols-5 gap-10">
            <div className="col-start-1 col-end-6 lg:col-end-5">
              <MDXProvider components={Rendering}>
                <MDXRenderer>{mdxBody(Content)}</MDXRenderer>
              </MDXProvider>
            </div>
            <div className="col-span-1">
              <div
                className="hidden xl:block"
                style={{
                  top: topPosition ? "120px" : "-160px",
                  position: topPosition ? "sticky" : "absolute",
                }}
              >
                {sections && sections.length > 0 && (
                  <AuthorHeaderCard
                    authorName={AuthorName}
                    authorTitle={AuthorJobTitle}
                    contents={[...sections].reverse().map(section => ({
                      sectionName: section,
                      active: debouncedSection === section,
                    }))}
                    image={gatsbyImageData}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="max-w-7xl mx-auto px-4 xl:px-0 mt-4 mb-4 grid grid-cols-5">
            <div className="col-start-1 col-end-6 lg:col-end-5">
              <AuthorFooterCard
                authorDescription={AuthorDescription}
                authorName={AuthorName}
                authorSocial={{
                  facebook: AuthorFacebook,
                  linkedin: AuthorLinkedIn,
                  twitter: AuthorTwitter,
                }}
                image={gatsbyImageData}
              />
            </div>
          </div>
          {/*<div className="max-w-7xl mx-auto px-4 xl:px-0 mt-12 mb-4">*/}
          {/*  <RecommendedArticles postID={postID} />*/}
          {/*</div>*/}
          {/*<div className="my-10">*/}
          {/*  <NewsletterBanner onClick={() => {}} />*/}
          {/*</div>*/}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default BlogPost;
