import { useEffect } from "react";

function isInViewport(element: HTMLHeadingElement) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default function useOnScreen(
  ref: React.RefObject<HTMLHeadingElement>,
  elemValue: string,
  setSection: (elemValue: string) => void
) {
  const checkSection = () => {
    if (ref.current) {
      if (isInViewport(ref.current)) {
        const location = ref.current.getBoundingClientRect().top;
        const intViewportHeight = window.innerHeight;
        if (((intViewportHeight - location) * 100) / intViewportHeight > 50) {
          setSection(elemValue);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkSection);

    return () => {
      window.removeEventListener("scroll", checkSection);
    };
  }, []);
}
