export type MDXProp = {
  childMdx: {
    __typename?: "Mdx" | undefined;
    body: string;
    excerpt: string;
  };
};

export const mdxBody = (props: MDXProp) => props.childMdx.body;

export const mdxExcerpt = (props: MDXProp) => props.childMdx.excerpt;
