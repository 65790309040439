import React from "react";
import BlockquoteTick from "../images/BlockquoteTicks.svg";
import { MDXProviderComponents } from "@mdx-js/react";
import MDXLink from "@components/MDXLink";

/** Custom Rendering for specific Markdown Elememts */
export const CustomMarkdownComponents: MDXProviderComponents = {
  h2: ({ ...props }) => {
    return <h2 className="my-9" {...props} />;
  },
  blockquote: ({ ...props }) => {
    const { children } = props;

    return (
      <div className="flex flex-row items-start">
        <div>
          <img
            className="mt-1"
            style={{
              minWidth: "12px",
            }}
            src={BlockquoteTick}
            alt="tick"
          />
        </div>
        <blockquote className="text-xl">{children}</blockquote>
      </div>
    );
  },
  a: props => <MDXLink {...props} />,
  p: props => (
    <p className={`font-serif ${props.className || ""}`}>{props.children}</p>
  ),
};
