import { ChevronRightIcon } from "@heroicons/react/outline";
import Link from "gatsby-link";
import React from "react";

type BreadcrumbParentType = {
  title: string;
  url: string;
};

const Breadcrumb = ({
  title,
  parents,
}: {
  title: string;
  parents?: BreadcrumbParentType[];
}) => {
  return (
    <nav className="flex z-10" aria-label="Breadcrumb">
      <ol className="flex items-center gap-x-4 m-0 flex-wrap">
        <li>
          <div>
            <Link to="/hub/" className="text-gray-400 hover:text-gray-500">
              <span className="text-accuRankerOrange-500">Hub</span>
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {parents &&
          parents.map(parent => {
            let url = parent.url;
            if (!url.endsWith("/")) {
              url = `${url}/`;
            }
            return (
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link to={url}>
                    <span className="ml-3 text-accuRankerOrange-500">
                      {parent.title}
                    </span>
                  </Link>
                </div>
              </li>
            );
          })}
        {/*{isBlog && (*/}
        {/*  <li>*/}
        {/*    <div className="flex items-center">*/}
        {/*      <ChevronRightIcon*/}
        {/*        className="flex-shrink-0 h-5 w-5 text-gray-400"*/}
        {/*        aria-hidden="true"*/}
        {/*      />*/}
        {/*      <Link to="/blog">*/}
        {/*        <span className="ml-3 text-accuRankerOrange-500">Blog</span>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  </li>*/}
        {/*)}*/}
        <li>
          <div className="flex items-center">
            <ChevronRightIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span className="ml-3 text-gray-400">{title}</span>
          </div>
        </li>
      </ol>
    </nav>
  );
};
export default Breadcrumb;
