import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Facebook from "../images/Social/Blog/facebook.svg";
import LinkedIn from "../images/Social/Blog/linkedin.svg";
import Twitter from "../images/Social/Blog/twitter.svg";
import FacebookFilled from "../images/Social/Blog/facebookFilled.svg";
import LinkedInFilled from "../images/Social/Blog/linkedinFilled.svg";
import TwitterFilled from "../images/Social/Blog/twitterFilled.svg";
import BulletInactive from "../images/BlogBulletInactive.svg";
import BulletActive from "../images/BlogBulletActive.svg";
import slugify from "slugify";

export type AuthorHeaderCardProps = {
  image: any;
  authorName: string;
  authorTitle: string;
  // TODO: Will share links passed as props, or calculated from window.path?
  //   shareLinks?: string
  contents: {
    sectionName: string;
    active: boolean;
  }[];
};

export type AuthorFooterCardProps = {
  image: any;
  authorName: string;
  authorDescription: string;
  authorSocial: {
    twitter: string;
    facebook: string;
    linkedin: string;
  };
};

export const AuthorHeaderCard = ({
  image,
  authorName,
  authorTitle,
  contents,
}: AuthorHeaderCardProps) => {
  return (
    <div className="flex flex-col gap-y-2 items-start md:w-80 bg-white p-12 rounded-xl border-2">
      <div className="flex-grow-0">
        {typeof image === "string" ? (
          <img
            className="mx-auto h-24 w-24 my-0 rounded-full"
            src={image}
            alt={authorName}
          />
        ) : (
          <GatsbyImage
            image={image}
            className="mx-auto h-24 w-24 my-0 rounded-full"
            alt={authorName}
          />
        )}
      </div>
      <div className="flex-grow-0">
        {/* Author Name & Title */}
        <div className="text-lg">
          <h3 className="text-black text-lg font-sans font-normal my-0">
            {authorName}
          </h3>
          <p className="text-gray-300 author-card-rale author-card-rale-gray my-0">
            {authorTitle}
          </p>
        </div>
        {/* Social */}
        <div className="mt-10">
          <h3 className="font-sans mb-2">Share this article</h3>
          <div className="flex justify-start gap-x-3">
            <a
              className="flex flex-row justify-center rounded-md w-14 py-2 bg-blue-400"
              href={`http://www.twitter.com/share?url=${window.location.href}`}
              target="_blank"
            >
              <span className="sr-only">Twitter Icon</span>
              <img
                src={Twitter}
                className="w-4 h-4 m-0 my-1"
                alt="twitter icon"
              />
            </a>
            <a
              className="flex flex-row  justify-center rounded-md w-14 py-2 bg-blue-600 "
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
            >
              <span className="sr-only">Facebook Icon</span>
              <img
                src={Facebook}
                className="w-4 h-4 m-0 my-1"
                alt="facebook icon"
              />
            </a>
            <a
              className="flex flex-row justify-center rounded-md w-14 py-2"
              style={{
                backgroundColor: "#0077B4",
              }}
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
              target="_blank"
            >
              <span className="sr-only">LinkedIn Icon</span>
              <img
                src={LinkedIn}
                className="w-4 h-4 m-0 my-1"
                alt="linkedin icon"
              />
            </a>
          </div>
        </div>
        {/* Contents */}
        {contents.length > 0 && (
          <div className="mt-10 flex-grow-0">
            <h3 className="font-sans mb-3">Contents</h3>
            <div className="flex justify-start space-x-3">
              <div className="pl-0 flex flex-col gap-4 mt-2">
                {contents.map(section => (
                  <div
                    className="flex flex-row gap-x-3"
                    key={section.sectionName}
                  >
                    {/* Bullet */}
                    <img
                      className="w-3 h-3 self-start mt-1 mb-0"
                      src={section.active ? BulletActive : BulletInactive}
                      alt={"list bullet"}
                    />
                    <p
                      className={`m-0 cursor-pointer author-card-rale ${
                        section.active
                          ? "text-accuRankerPurple-900"
                          : "author-card-rale-gray"
                      }`}
                      onClick={() => {
                        /** Scroll to title */
                        if (document && document !== null) {
                          const id = `#${slugify(section.sectionName, {
                            strict: true,
                            lower: true,
                          })}`;
                          document?.querySelector(id)?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }
                      }}
                    >
                      {section.sectionName}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const AuthorFooterCard = ({
  image,
  authorName,
  authorDescription,
  authorSocial,
}: AuthorFooterCardProps) => {
  return (
    <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 space-x-3 items-start bg-gray-100 p-12 rounded-xl">
      <div className="flex-shrink-0">
        {typeof image === "string" ? (
          <img
            className="mx-auto h-24 w-24 rounded-full"
            src={image}
            alt={authorName}
          />
        ) : (
          <GatsbyImage
            image={image}
            className="mx-auto h-24 w-24 rounded-full"
            alt={authorName}
          />
        )}
      </div>
      <div className="">
        {/* Author Name & Title */}
        <div className="font-medium text-lg space-y-1">
          <p className="text-gray-400 font-light my-0">Article by:</p>
          <h3 className="text-accuRankerPurple-900 text-md font-normal font-sans">
            {authorName}
          </h3>
        </div>
        {/* Author Description */}
        <div className="mt-6">
          <p
            className="text-gray-600 text-sm"
            style={{
              lineHeight: "1.375rem",
            }}
          >
            {authorDescription}
          </p>
        </div>
        {/* Social */}
        <div className="mt-10">
          <div className="flex flex-start space-x-3">
            {authorSocial.twitter && (
              <div className="flex flex-row justify-center rounded-md w-14 py-2">
                <a href={authorSocial.twitter}>
                  <span className="sr-only">Twitter Icon</span>
                  <img
                    src={TwitterFilled}
                    className="w-8 h-8"
                    alt="twitter icon"
                  />
                </a>
              </div>
            )}
            {authorSocial.facebook && (
              <div className="flex flex-row justify-center rounded-md w-14 py-2">
                <a href={authorSocial.facebook}>
                  <span className="sr-only">Facebook Icon</span>
                  <img
                    src={FacebookFilled}
                    className="w-8 h-8"
                    alt="facebook icon"
                  />
                </a>
              </div>
            )}
            {authorSocial.linkedin && (
              <div className="flex flex-row justify-center rounded-md w-14 py-2 ">
                <a href={authorSocial.linkedin}>
                  <span className="sr-only">LinkedIn Icon</span>
                  <img
                    src={LinkedInFilled}
                    className="w-8 h-8"
                    alt="linkedin icon"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
